import FooterSocialMedia from 'components/FooterSocialMedia'
import FooterWidget from 'components/FooterWidget'
import React from 'react'
import { menuItems, footerNav } from '../data/footer.data'
import Link from 'components/Link'

const FooterLogo = () => {
  return <img src="/assets/images/trida_48.png" />
}

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer__branding">
          <div className="footer-branding__left-section">
            <FooterLogo />
            <p className="footer-branding__company-name">
              © {new Date().getFullYear()}{' '}
              <Link
                to="https://tridalabs.com"
                className="footer-branding__home-link"
              >
                {' '}
                Trida Software Labs Private Limited
              </Link>
            </p>
          </div>
          <FooterSocialMedia className="footer__social-media" />
          <ul className="footer-widget__links footer-branding__links">
            {footerNav.map(({ path, label }, i) => (
              <li key={i} className="footer-branding__link-item">
                <a
                  href={path}
                  key={i}
                  target="_blank"
                  className="footer-widget__link"
                >
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Footer
