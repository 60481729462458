import React from 'react'

const FooterWidget = props => {
  const { title, items } = props
  return (
    <div className="footer-widget">
      <h2 className="footer-widget__heading">{title}</h2>
      <ul className="footer-widget__links">
        {items.map(({ path, label, target }, index) => (
          <li key={index}>
            <a href={path} target={target} className="footer-widget__link">
              {label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default React.memo(FooterWidget)