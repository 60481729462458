export const menuItems = [
  {
    id: 1,
    title: 'COMPANY',
    items: [
      {
        path: 'https://tridalabs.com/about',
        label: 'About Us',
        target: '_blank',
      },
      {
        path: 'https://tridalabs.com/careers',
        label: 'Careers',
        target: '_blank',
      },
      {
        path: 'mailto:support@tridalabs.com',
        label: 'Contact Us',
      },
    ],
  },
  {
    id: 2,
    title: 'LEARN',
    items: [
      {
        path: 'https://tridalabs.com/#useful-features',
        label: 'Features',
        target: '_blank',
      },
      {
        path: 'https://tridalabs.com/#pricing',
        label: 'Pricing',
        target: '_blank',
      },
      {
        path: 'https://blog.tridalabs.com/',
        label: 'Blog',
        target: '_blank',
      },
      {
        path: '/',
        label: 'Documentation',
      },
    ],
  },
  {
    id: 3,
    title: 'SOCIAL',
    items: [
      {
        path: 'https://www.linkedin.com/company/tridalabs',
        label: 'Linkedin',
        target: '_blank',
      },
      {
        path: 'https://github.com/tridalabs',
        label: 'GitHub',
        target: '_blank',
      },
      {
        path: 'https://twitter.com/tridalabs',
        label: 'Twitter',
        target: '_blank',
      },
      {
        path: 'https://www.medium.com/tridalabs',
        label: 'Medium',
        target: '_blank',
      },
      {
        path: 'https://facebook.com/tridalabs',
        label: 'Facebook',
        target: '_blank',
      },
      {
        path: 'https://hub.docker.com/u/tridalabs',
        label: 'Docker',
        target: '_blank',
      },
    ],
  },
  {
    id: 4,
    title: 'LEGAL',
    items: [
      {
        path: 'https://tridalabs.com/terms',
        label: 'Terms of Use',
      },
      {
        path: 'https://tridalabs.com/privacy',
        label: 'Privacy Policy',
      },
      {
        path: 'https://tridalabs.com/disclaimer',
        label: 'Disclaimer',
      },
    ],
  },
]

export const footerNav = [
  {
    path: 'https://tridalabs.com/privacy',
    label: 'Privacy Policy',
  },
  {
    path: 'https://tridalabs.com/terms',
    label: 'Terms & Conditions',
  },
]
