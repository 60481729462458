import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookSquare,
  faLinkedin,
  faTwitterSquare,
  faMedium,
  faGithubSquare,
  faDocker,
} from '@fortawesome/free-brands-svg-icons'

const socialMedias = [
  {
    link: 'https://www.linkedin.com/company/tridalabs',
    title: 'LinkedIn',
    icon: faLinkedin,
    className: 'footer-social-media__other',
  },
  {
    link: 'https://facebook.com/tridalabs',
    title: 'Facebook',
    icon: faFacebookSquare,
    className: 'footer-social-media__other',
  },
  {
    link: 'https://twitter.com/tridalabs',
    title: 'Twitter',
    icon: faTwitterSquare,
    className: 'footer-social-media__other',
  },
  {
    link: 'https://www.medium.com/tridalabs',
    title: 'Medium',
    icon: faMedium,
    className: 'footer-social-media__other',
  },
  {
    link: 'https://www.github.com/tridalabs',
    title: 'GitHub',
    icon: faGithubSquare,
    className: 'footer-social-media__other',
  },
  {
    link: 'https://hub.docker.com/u/tridalabs',
    title: 'Docker',
    icon: faDocker,
    className: 'footer-social-media__other',
  },
]

const FooterSocialMedia = props => {
  const { className } = props
  return (
    <div className={`${className} footer-social-media`}>
      {socialMedias.map(sm => (
        <a
          href={sm.link}
          title={sm.title}
          target="_blank"
          className={`footer-social-media__item ${sm.className}`}
        >
          <FontAwesomeIcon icon={sm.icon} />
        </a>
      ))}
    </div>
  )
}

export default React.memo(FooterSocialMedia)
